import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree,} from '@angular/router';

import {map, Observable} from 'rxjs';
import {AuthService} from "../../features/auth/data/services/auth.service";

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean | UrlTree> => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.checkAuth().pipe(
    map((res) => {
      console.log('isLoggedIn:', res.isAuthenticated);
      if (!res.isAuthenticated) {
        console.log('User is not logged in, redirecting to /login');
        return router.createUrlTree(['/login']);
      }

      const accessToken = res.accessToken;
      console.log('accessToken:', accessToken);
      if (!accessToken) {
        console.log('No access token found, redirecting to /login');
        return router.createUrlTree(['/login']);
      }

      return true;
    }),
  );
};
