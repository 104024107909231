import { createReducer, on } from '@ngrx/store';
import { User } from '../domain/models/user';
import {
  loginButtonClicked,
  loginError,
  loginNotAuthenticated,
  loginSuccess,
  logoutButtonClicked,
  logoutSuccess,
  onInitLoginPage,
} from './auth.actions';

export interface AuthState {
  user?: User;
  loading: boolean;
  error?: string;
}

export const initialState: AuthState = {
  user: undefined,
  loading: false,
  error: '',
};

export const authReducer = createReducer(
  initialState,

  on(onInitLoginPage, (state) => ({
    ...state,
  })),

  on(loginNotAuthenticated, (state) => ({
    ...initialState,
  })),

  on(loginButtonClicked, () => ({
    ...initialState,
    loading: true,
  })),

  on(loginSuccess, (state, action) => ({
    ...state,
    user: action.user,
    loading: false,
  })),

  on(loginError, (state, action) => ({
    ...state,
    error: action.error,
    loading: false,
  })),

  on(logoutButtonClicked, (state: AuthState) => ({
    ...state,
    user: undefined,
    loading: false,
  })),

  on(logoutSuccess, (state, action) => ({
    ...initialState,
  })),

  on(loginError, (state, action) => ({
    ...state,
  }))
);
