import {createFeatureSelector, createSelector} from "@ngrx/store";
import {HomeState} from "./home.reducer";

export const selectHomeState = createFeatureSelector<HomeState>('home');

export const selectUploadedFiles = createSelector(
  selectHomeState,
  (state: HomeState) => state.uploadedFiles
);

export const selectConvertedFiles = createSelector(
  selectHomeState,
  (state: HomeState) => state.convertedFiles
);
