import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map} from 'rxjs/operators';
import * as HomeActions from './home.actions';
import {Store} from '@ngrx/store';
import {HomeState} from './home.reducer';
import {FilesService} from "../data/services/files.service";

@Injectable()
export class HomeEffects {
  fileService = inject(FilesService);

  conversionComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.conversionComplete),
      map(action => action.fileId),
      map(fileId => HomeActions.deleteUploadedFile({fileId}))
    )
  );

  constructor(private actions$: Actions, private store: Store<HomeState>) {
  }
}
