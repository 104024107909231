import {PassedInitialConfig} from "angular-auth-oidc-client";

export const environment = {
  production: false,
  apiUrl: 'https://api-webconverter-dev.audaces.com/',
  url_audaces_id: 'https://login-dev.audaces.com/core',
  audaces_id_key: 'audaces-web-converter',
  callback_url: 'https://webconverter-dev.audaces.com',
  scope: 'openid profile email',
};

export const authOptions: PassedInitialConfig = {
  config: {
    authority: environment.url_audaces_id,
    clientId: environment.audaces_id_key,
    redirectUrl: environment.callback_url,
    scope: environment.scope,
    triggerAuthorizationResultEvent: true,
    responseType: 'id_token token',
    postLogoutRedirectUri: environment.callback_url,
    silentRenew: false,
    startCheckSession: true,
  },
};
