import {ConvertedFile, JobStatus, UploadedFile} from "../models/converted-file";
import {createReducer, on} from "@ngrx/store";
import * as HomeActions from './home.actions';
import {moveItemInArray} from "@angular/cdk/drag-drop";

export interface HomeState {
  uploadedFiles: UploadedFile[];
  convertedFiles: ConvertedFile[];
  loading: boolean;
}

export const initialState: HomeState = {
  uploadedFiles: [],
  convertedFiles: [],
  loading: false
};

export const homeReducer = createReducer(
  initialState,
  on(HomeActions.loadUploadedFiles, (state) => ({
    ...state,
    loading: true
  })),
  on(HomeActions.loadUploadedFilesSuccess, (state, {files}) => ({
    ...state,
    uploadedFiles: files,
    loading: false
  })),
  on(HomeActions.loadConvertedFiles, (state) => ({
    ...state,
    loading: true
  })),
  on(HomeActions.loadConvertedFilesSuccess, (state, {files}) => ({
    ...state,
    convertedFiles: files,
    loading: false
  })),
  on(HomeActions.addUploadedFile, (state, {file}) => {
    const updatedFiles = [...state.uploadedFiles, {...file, index: state.uploadedFiles.length}];
    return {
      ...state,
      uploadedFiles: updatedFiles,
      loading: false
    };
  }),
  on(HomeActions.addConvertedFile, (state, {file}) => ({
    ...state,
    convertedFiles: [...state.convertedFiles, file]
  })),
  on(HomeActions.updateUploadedFileStatus, (state, {fileId, status}) => ({
    ...state,
    uploadedFiles: state.uploadedFiles.map(file =>
      file.fileId === fileId ? {...file, status} : file
    )
  })),
  on(HomeActions.reorderUploadedFiles, (state, {previousIndex, currentIndex}) => {
    const updatedFiles = [...state.uploadedFiles];
    moveItemInArray(updatedFiles, previousIndex, currentIndex);
    const reindexFiles = updatedFiles.map((file, index) => ({
      ...file,
      index: index
    }));

    return {...state, uploadedFiles: reindexFiles};
  }),
  on(HomeActions.resetFileStatus, (state) => {
    const updatedFiles = state.uploadedFiles.map(file => ({
      ...file,
      status: JobStatus.Wait
    }));
    return {...state, uploadedFiles: updatedFiles};
  }),
  on(HomeActions.deleteUploadedFile, (state, {fileId}) => {
    console.log('Deleting file with ID:', fileId);
    return {
      ...state,
      uploadedFiles: state.uploadedFiles.filter(file => file.fileId !== fileId),
    }
  }),
  on(HomeActions.clearHomeState, () => initialState),
  on(HomeActions.deleteConvertedFile, (state, {fileId}) => {
      return {
        ...state,
        convertedFiles: state.convertedFiles.filter(file => file.fileId !== fileId),
      }
    }
  )
);
