<div class="container">
  <div class="nav-bar">
    <img alt="" class="logo" src="https://marketplace.febratex.com.br/media/marketplace/logotipos/logo-1920x480px.png"/>
    <div class="user-info-div">
      @if (error$ | async; as error) {
        <h1 style="color: red">Não foi possível: {{ error }}</h1>
      }
      @if (user$ | async; as user) {
        <h2 class="username">{{ user.name }}</h2>
        <div class="dropdown" (click)="toggleDropdownMenu($event)">
          <div class="pfp-div"></div>
          <div class="dropdown-content" [class.show]="isDropdownMenuOpen">
            <button (click)="logoutButtonClick()">Logout</button>
          </div>
        </div>
      }
    </div>
  </div>
  <div class="app-div">
    <div class="converter-div">
      <app-file-box [class.blocked]="isFileBoxBlocked"></app-file-box>
      @if (uploadedFiles$ | async; as files) {
        @if (files.length > 0) {
          @if (!isFileBoxBlocked) {
            <button (click)="sendQueue()" class="upload-btn">
              Convert!
            </button>
            <button (click)="clearQueue()" class="clear-queue-btn">
              Clear Queue
              <span class="material-symbols-rounded">
                delete_forever
              </span>
            </button>
          } @else {
            <button (click)="cancelQueue()" class="stop-btn">
              Stop Conversion
            </button>
          }
        }
      }
      <div class="files-container">
        @if (uploadedFiles$ | async; as files) {
          @if (files.length > 0) {
            <div class="scrollarea" cdkDropList (cdkDropListDropped)="drop($event)">
              @for (file of files; track $index) {
                <app-processing-file-card [class.blocked]="isFileBoxBlocked"
                                          [fileId]="file.fileId"
                                          [fileName]="file.fileName"
                                          [fileSize]="file.fileSize / 1024"
                                          [status]="file.status"
                                          [queuePosition]="$index"
                                          cdkDrag
                />
              }
            </div>
          }
        }
        @if (convertedFiles$ | async; as convertedFiles) {
          @if (convertedFiles.length > 0) {
            <div class="converted-files">
              @for (file of convertedFiles; track file.fileId) {
                <app-converted-file-card [fileId]="file.fileId"
                                         [fileName]="file.fileName"
                                         [fileSize]="file.fileSize / 1024"
                                         [status]="file.status"
                                         [fileUrl]="file.fileUrl"
                />
              }
            </div>
          }
        }
      </div>
    </div>
  </div>
</div>
