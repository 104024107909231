import {inject, Injectable} from '@angular/core';
import * as signalR from '@microsoft/signalr';
import {Store} from '@ngrx/store';
import {JobStatusMessage} from '../../models/converted-file';
import {updateUploadedFileStatus} from "../../state/home.actions";
import {environment} from "../../../../../environments/environment";


@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  store = inject(Store)
  private hubConnection!: signalR.HubConnection;
  private baseUrl = environment.apiUrl;

  constructor() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${this.baseUrl}JobStatusHub`)
      .build();

    this.hubConnection
      .start()
      .then(() => console.log('Connected to signalR hub'))
      .catch(err => console.error('Error connecting to SignalR', err));

    this.listenToJobStatusUpdates();
  }

  private listenToJobStatusUpdates() {
    this.hubConnection.on('ReceiveJobStatus', (message: JobStatusMessage) => {
      this.store.dispatch(updateUploadedFileStatus({fileId: message.fileId, status: message.status}));
    });
  }
}
