<div class="login-container">
  @if (error$ | async; as error) {
    <h1 style="color: red">Não foi possível: {{ error }}</h1>
  }
  <div class="login-btn-container">
    <div class="logo-container">
      <img alt="" class="logo"
           src="https://marketplace.febratex.com.br/media/marketplace/logotipos/logo-1920x480px.png"/>
      Web Converter
    </div>
    <button (click)="loginButtonClick()" class="login-btn">Login</button>
  </div>
</div>
