import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {localStorageSync} from 'ngrx-store-localstorage';
import {authReducer, AuthState} from './features/auth/state/auth.reducer';
import {homeReducer, HomeState} from "./features/home/state/home.reducer";

export interface AppState {
  auth: AuthState;
  home: HomeState;
}

export const reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  home: homeReducer
};

export function localStorageSyncReducer(
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
  return localStorageSync({
    keys: [
      {auth: ['user']},
      {home: ['uploadedFiles', 'convertedFiles']}
    ],
    rehydrate: true,
  })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [
  localStorageSyncReducer,
];
