import { createAction, props } from '@ngrx/store';
import { User } from '../domain/models/user';

export const onInitLoginPage = createAction('[Login Page] onInit Login Page');

export const loginButtonClicked = createAction(
  '[Login Button] Login Button Clicked'
);

export const loginNotAuthenticated = createAction(
  '[Auth Effects] Login Not Authenticated'
);

export const loginSuccess = createAction(
  '[Auth Effects] Login Success',
  props<{ user: User }>()
);

export const loginError = createAction(
  '[Auth Effects] Login Error',
  props<{ error: string }>()
);

export const logoutButtonClicked = createAction(
  '[Logout Button] Logout Button Clicked'
);

export const logoutSuccess = createAction('[Auth Effects] Logout Error');

export const logoutError = createAction(
  '[Auth Effects] Login Error',
  props<{ error: string }>()
);
