import {createAction, props} from "@ngrx/store";
import {ConvertedFile, JobStatus, UploadedFile} from "../models/converted-file";

export const loadUploadedFiles = createAction(
  '[Files] Load Uploaded Files'
);

export const loadUploadedFilesSuccess = createAction(
  '[Files] Load Uploaded Files Success',
  props<{ files: UploadedFile[] }>()
);

export const loadConvertedFiles = createAction(
  '[Files] Load Converted Files'
);

export const loadConvertedFilesSuccess = createAction(
  '[Files] Load Converted Files Success',
  props<{ files: ConvertedFile[] }>()
);

export const addUploadedFile = createAction(
  '[Files] Add Uploaded File',
  props<{ file: UploadedFile }>()
);

export const addConvertedFile = createAction(
  '[Files] Add Converted File',
  props<{ file: ConvertedFile }>()
);

export const updateUploadedFileStatus = createAction(
  '[Files] Update Uploaded File Status',
  props<{ fileId: string, status: JobStatus }>()
);

export const reorderUploadedFiles = createAction(
  '[UploadedFiles] Reorder Files',
  props<{ previousIndex: number, currentIndex: number }>()
);

export const resetFileStatus = createAction(
  '[UploadedFiles] Reset File Status'
);

export const updateUploadedFile = createAction(
  '[FileBox] Update File Card',
  props<{ fileId: string; fileData: FormData }>()
);

export const deleteUploadedFile = createAction(
  '[Home] Delete Uploaded File',
  props<{ fileId: string }>()
);

export const conversionComplete = createAction(
  '[Home] Conversion Complete',
  props<{ fileId: string }>()
);

export const clearHomeState = createAction('[Home] Clear State');

export const deleteConvertedFile = createAction(
  '[Home] Delete Converted File',
  props<{ fileId: string }>()
)
