import { Component } from '@angular/core';

@Component({
  selector: 'app-archived-file-card',
  standalone: true,
  imports: [],
  templateUrl: './archived-file-card.component.html',
  styleUrl: './archived-file-card.component.scss'
})
export class ArchivedFileCardComponent {

}
