import {Component, inject, Input} from '@angular/core';
import {Store} from "@ngrx/store";
import {JobStatus} from "../../../models/converted-file";
import {deleteUploadedFile} from "../../../state/home.actions";

@Component({
  selector: 'app-processing-file-card',
  standalone: true,
  imports: [],
  templateUrl: './processing-file-card.component.html',
  styleUrl: './processing-file-card.component.scss',
})
export class ProcessingFileCardComponent {
  @Input() fileId: string = '';
  @Input() fileName: string = '';
  @Input() queuePosition: number = 0;
  @Input() fileSize: number = 0;
  @Input() jobId: string = '';
  @Input() status: JobStatus = JobStatus.Wait;
  @Input() fileUrl: string = '';
  store = inject(Store);
  protected readonly JobStatus = JobStatus;

  protected deleteUploadedFile() {
    this.store.dispatch(deleteUploadedFile({fileId: this.fileId}));
  }
}
