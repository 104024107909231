import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  private baseUrl = environment.apiUrl;
  private http = inject(HttpClient)

  constructor() {
  }

  detectConversionMode(fileName: string): string {
    const extension = fileName.split('.').pop()?.toLowerCase();
    if (extension === 'zip') {
      return 'Gerber';
    } else if (extension === 'mdl') {
      return 'Lectra';
    } else {
      return 'Unknown';
    }
  }

  deleteFileFromBucket(fileName: string) {
    return this.http.delete(`${this.baseUrl}Blob?fileName=${fileName}`);
  }

}


