import {Injectable} from '@angular/core';
import {DBSchema, IDBPDatabase, openDB} from 'idb';
import {FileData} from "../../models/converted-file";

interface MyDB extends DBSchema {
  fileData: {
    key: string;
    value: FileData;
  };
}

@Injectable({
  providedIn: 'root'
})
export class IndexedDbService {
  private db: Promise<IDBPDatabase<MyDB>>;

  constructor() {
    this.db = this.initDB();
  }

  async addOrUpdateFileData(fileId: string, file: File) {
    const db = await this.db;
    await db.put('fileData', {fileId, data: file});
  }

  async getFileData(fileId: string): Promise<FileData> {
    const db = await this.db;
    const data = await db.get('fileData', fileId);
    if (data == undefined) throw new Error("File data not found");

    return data;
  }

  async getAllFiles(): Promise<FileData[]> {
    const db = await this.db;
    return db.getAll('fileData');
  }

  async deleteFile(fileId: string) {
    const db = await this.db;
    await db.delete('fileData', fileId);
  }

  private async initDB() {
    return openDB<MyDB>('uploadedFilesDB', 1, {
      upgrade(db) {
        db.createObjectStore('fileData', {keyPath: 'fileId'});
      },
    });
  }
}
