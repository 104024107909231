import {inject} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, map, of, tap} from 'rxjs';
import {AuthService} from '../data/services/auth.service';
import {
  loginButtonClicked,
  loginError,
  loginNotAuthenticated,
  loginSuccess,
  logoutButtonClicked,
  logoutError,
  logoutSuccess,
  onInitLoginPage,
} from './auth.actions';
import {clearHomeState} from "../../home/state/home.actions";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";

export class AuthEffects {
  private actions$ = inject(Actions);
  private authService = inject(AuthService);
  loginButtonClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginButtonClicked),
        tap(() => this.authService.login())
      ),
    {dispatch: false}
  );
  onInitLoginPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(onInitLoginPage),
      exhaustMap(() =>
        this.authService.checkAuth().pipe(
          map((auth) => {
            if (auth.userData) {
              return loginSuccess({user: auth.userData});
            } else if (!auth.userData) {
              return loginNotAuthenticated();
            } else {
              throw new Error(auth.errorMessage);
            }
          }),
          catchError((error: { message: string }) =>
            of(loginError({error: error.message}))
          )
        )
      )
    )
  );
  private router = inject(Router);
  logoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutSuccess),
        tap(() => {
          sessionStorage.clear();
          this.router.navigate(['/login']).then();
        })
      ),
    {dispatch: false}
  );
  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginSuccess),
        tap(() => {
          this.router.navigate(['']).then();
        })
      ),
    {dispatch: false}
  );
  private store = inject(Store);
  logoutButtonClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutButtonClicked),
      exhaustMap(() =>
        this.authService.logout().pipe(
          map(() => {
            this.store.dispatch(clearHomeState());
            this.store.dispatch(logoutSuccess());
            return logoutSuccess();
          }),
          catchError((error: { message: string }) =>
            of(logoutError({error: error.message}))
          )
        )
      )
    )
  );

}
