import {Component, inject, OnInit} from '@angular/core';
import {AsyncPipe} from "@angular/common";
import {CdkDrag, CdkDropList} from "@angular/cdk/drag-drop";
import {
  ConvertedFileCardComponent
} from "../../../../home/ui/components/converted-file-card/converted-file-card.component";
import {FileBoxComponent} from "../../../../home/ui/components/file-box/file-box.component";
import {
  ProcessingFileCardComponent
} from "../../../../home/ui/components/processing-file-card/processing-file-card.component";
import {selectAuthError} from "../../../../auth/state/auth.selectors";
import {Store} from "@ngrx/store";
import {loginButtonClicked, onInitLoginPage} from "../../../../auth/state/auth.actions";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    AsyncPipe,
    CdkDrag,
    CdkDropList,
    ConvertedFileCardComponent,
    FileBoxComponent,
    ProcessingFileCardComponent
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  store = inject(Store);
  protected error$ = this.store.select(selectAuthError);

  ngOnInit(): void {
    this.store.dispatch(onInitLoginPage());
  }

  protected loginButtonClick() {
    this.store.dispatch(loginButtonClicked());
  }
}
