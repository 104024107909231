<div class="card">
  <span class="material-symbols-rounded draft">drag_indicator</span>
  <span class="material-symbols-rounded draft">draft</span>
  <div class="text-div">
    <p class="file-name">{{ fileName }}</p>
    <p class="file-size">{{ fileSize.toFixed(2) }}KB</p>
  </div>
  <p class="status">{{ JobStatus[status] }}</p>
  <a class="material-symbols-rounded download" href="{{fileUrl}}">download</a>
  <button (click)="deleteConvertedFile()">
    <span class="material-symbols-rounded cancel">cancel</span>
  </button>
</div>
