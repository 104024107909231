<div class="container">
  <ngx-file-drop (onFileDrop)="dropped($event)" (onFileLeave)="fileLeave($event)" (onFileOver)="fileOver($event)"
                 [multiple]="true"
                 class="converter-box" contentClassName="converter" dropZoneClassName="file-drop">
    <ng-template let-openFileSelector="openFileSelector" ngx-file-drop-content-tmp>
      <p class="drag-n-drop">Drag and drop your .mdl and .zip files!</p>
      <p class="subtitle">Or</p>
      <button (click)="openFileSelector()" class="upload-btn" type="button">Click to browse files</button>
      <p class="error">{{ errorMessage }}</p>
    </ng-template>
  </ngx-file-drop>
</div>


